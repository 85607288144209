@font-face {
  font-family: 'AvertaPE-Black';
  src: url('../assets/fonts/AvertaPE-Black.eot');
  src: url('../assets/fonts/AvertaPE-Black.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvertaPE-Black.woff2') format('woff2'),
    url('../assets/fonts/AvertaPE-Black.woff') format('woff'),
    url('../assets/fonts/AvertaPE-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'AvertaPE-BlackItalic';
  src: url('../assets/fonts/AvertaPE-BlackItalic.eot');
  src: url('../assets/fonts/AvertaPE-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvertaPE-BlackItalic.woff2') format('woff2'),
    url('../assets/fonts/AvertaPE-BlackItalic.woff') format('woff'),
    url('../assets/fonts/AvertaPE-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'AvertaPE-Bold';
  src: url('../assets/fonts/AvertaPE-Bold.eot');
  src: url('../assets/fonts/AvertaPE-Bold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvertaPE-Bold.woff2') format('woff2'),
    url('../assets/fonts/AvertaPE-Bold.woff') format('woff'),
    url('../assets/fonts/AvertaPE-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'AvertaPE-BoldItalic';
  src: url('../assets/fonts/AvertaPE-BoldItalic.eot');
  src: url('../assets/fonts/AvertaPE-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvertaPE-BoldItalic.woff2') format('woff2'),
    url('../assets/fonts/AvertaPE-BoldItalic.woff') format('woff'),
    url('../assets/fonts/AvertaPE-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'AvertaPE-Extrabold';
  src: url('../assets/fonts/AvertaPE-Extrabold.eot');
  src: url('../assets/fonts/AvertaPE-Extrabold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvertaPE-Extrabold.woff2') format('woff2'),
    url('../assets/fonts/AvertaPE-Extrabold.woff') format('woff'),
    url('../assets/fonts/AvertaPE-Extrabold.ttf') format('truetype');
}

@font-face {
  font-family: 'AvertaPE-ExtraboldItalic';
  src: url('../assets/fonts/AvertaPE-ExtraboldItalic.eot');
  src: url('../assets/fonts/AvertaPE-ExtraboldItalic.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvertaPE-ExtraboldItalic.woff2') format('woff2'),
    url('../assets/fonts/AvertaPE-ExtraboldItalic.woff') format('woff'),
    url('../assets/fonts/AvertaPE-ExtraboldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'AvertaPE-Extrathin';
  src: url('../assets/fonts/AvertaPE-Extrathin.eot');
  src: url('../assets/fonts/AvertaPE-Extrathin.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvertaPE-Extrathin.woff2') format('woff2'),
    url('../assets/fonts/AvertaPE-Extrathin.woff') format('woff'),
    url('../assets/fonts/AvertaPE-Extrathin.ttf') format('truetype');
}

@font-face {
  font-family: 'AvertaPE-Light';
  src: url('../assets/fonts/AvertaPE-Light.eot');
  src: url('../assets/fonts/AvertaPE-Light.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvertaPE-Light.woff2') format('woff2'),
    url('../assets/fonts/AvertaPE-Light.woff') format('woff'),
    url('../assets/fonts/AvertaPE-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'AvertaPE-ExtrathinItalic';
  src: url('../assets/fonts/AvertaPE-ExtrathinItalic.eot');
  src: url('../assets/fonts/AvertaPE-ExtrathinItalic.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvertaPE-ExtrathinItalic.woff2') format('woff2'),
    url('../assets/fonts/AvertaPE-ExtrathinItalic.woff') format('woff'),
    url('../assets/fonts/AvertaPE-ExtrathinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'AvertaPE-Regular';
  src: url('../assets/fonts/AvertaPE-Regular.eot');
  src: url('../assets/fonts/AvertaPE-Regular.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvertaPE-Regular.woff2') format('woff2'),
    url('../assets/fonts/AvertaPE-Regular.woff') format('woff'),
    url('../assets/fonts/AvertaPE-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'AvertaPE-RegularItalic';
  src: url('../assets/fonts/AvertaPE-RegularItalic.eot');
  src: url('../assets/fonts/AvertaPE-RegularItalic.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvertaPE-RegularItalic.woff2') format('woff2'),
    url('../assets/fonts/AvertaPE-RegularItalic.woff') format('woff'),
    url('../assets/fonts/AvertaPE-RegularItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'AvertaPE-LightItalic';
  src: url('../assets/fonts/AvertaPE-LightItalic.eot');
  src: url('../assets/fonts/AvertaPE-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvertaPE-LightItalic.woff2') format('woff2'),
    url('../assets/fonts/AvertaPE-LightItalic.woff') format('woff'),
    url('../assets/fonts/AvertaPE-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'AvertaPE-Semibold';
  src: url('../assets/fonts/AvertaPE-Semibold.eot');
  src: url('../assets/fonts/AvertaPE-Semibold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvertaPE-Semibold.woff2') format('woff2'),
    url('../assets/fonts/AvertaPE-Semibold.woff') format('woff'),
    url('../assets/fonts/AvertaPE-Semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'AvertaPE-SemiboldItalic';
  src: url('../assets/fonts/AvertaPE-SemiboldItalic.eot');
  src: url('../assets/fonts/AvertaPE-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvertaPE-SemiboldItalic.woff2') format('woff2'),
    url('../assets/fonts/AvertaPE-SemiboldItalic.woff') format('woff'),
    url('../assets/fonts/AvertaPE-SemiboldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'AvertaPE-Thin';
  src: url('../assets/fonts/AvertaPE-Thin.eot');
  src: url('../assets/fonts/AvertaPE-Thin.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvertaPE-Thin.woff2') format('woff2'),
    url('../assets/fonts/AvertaPE-Thin.woff') format('woff'),
    url('../assets/fonts/AvertaPE-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'AvertaPE-ThinItalic';
  src: url('../assets/fonts/AvertaPE-ThinItalic.eot');
  src: url('../assets/fonts/AvertaPE-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/AvertaPE-ThinItalic.woff2') format('woff2'),
    url('../assets/fonts/AvertaPE-ThinItalic.woff') format('woff'),
    url('../assets/fonts/AvertaPE-ThinItalic.ttf') format('truetype');
}
